<template>
    <div>Esporta Tessere Magazzino</div>
</template>

<script>
 import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

 export default {
     mounted() {
         this.$store.dispatch(SET_BREADCRUMB, [
             { title: "Impostazioni", route: "/settings" },
             { title: "Tessere Magazzino", route: "/settings/membercard/index" },
             { title: "Esporta Tessere Magazzino" }
         ]);
     }
 };
</script>
